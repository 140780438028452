
<template>
  <!-- 订单管理 -->
  <div class="">
    <ax-table
      ref="logTable"
      :columns="columns"
      :show-search="true"
      :searchForm="searchForm"
      :isCard="true"
      :dataSourceApi="api.list"
        :dataSourceParams="dataSourceParams"
        :scroll="{ y: '55vh', x: '80vw' }"
      @action-column-click="actionColumnClick"
    >
    <template slot="status" slot-scope="{ record }">
            <div v-if="record.status === 0">未支付</div>
            <div v-else-if="record.status === 1">已完成</div>
            <div v-else-if="record.status === 2">退款中</div>
            <div v-else-if="record.status === 3">已关闭</div>
    </template>
    <template slot="returnName" slot-scope="{ record }">
            <div>{{record.returnName || '--'}}</div>
    </template>
    </ax-table>
    <detaila ref="detaila" @refreshData="refreshData"></detaila>
  </div>
</template>
   
   <script>
const searchForm = [
  {
    label: "服务项目",
    type: "",
    model: "productName",
    options: {
      placeholder: "请输入服务项目",
    },
    formItem: {},
    col: { span: 6 },
  },
  {
    label: "订单编号",
    type: "",
    model: "orderId",
    options: {
      placeholder: "请输入订单编号",
    },
    formItem: {},
    col: { span: 6 },
  },
  {
    label: "退款时间",
    type: "datePicker",
    model: "createTime",
    options: {
      placeholder: "请选择下单时间",
    },
    formItem: {},
    col: { span: 6 },
  },
];
import api from "./api";
import { initGridFormData } from "@/common/tools";
import detaila from "./components/details.vue";
export default {
  components: { detaila },
  data() {
    return {
      api,
      columns: this.$ax.tools.initColumn(
        [
          {
            title: "订单编号",
            dataIndex: "orderId",
            ellipsis: true,
            key: 1,
            width: 120,
          },
          {
            title: "服务项目",
            dataIndex: "productName",
            ellipsis: false,
            key: 2,
            width: 80,
          },
          {
            title: "认证公司",
            dataIndex: "enterpriseName",
            ellipsis: true,
            key: 3,
            width: 100,
          },
          {
            title: "服务费用",
            dataIndex: "returnAmount",
            ellipsis: false,
            width: 80,
            key: 5,
          },
          {
            title: "客户名称",
            dataIndex: "returnName",
            ellipsis: false,
            key: 6,
          },
          {
            title: "退款时间",
            dataIndex: "createTime",
            ellipsis: false,
            key: 7,
          },
        ],
        true,
        {
          maxWidth: 100,
          actions: [
            {
              props: (record) => {
                return {
                  text: "审核",
                  type: "#2a5cff",
                  name: "detail",
                  link: true,
                  // disabled: !(record.status === "1"),
                };
              },
            },

          ],
        }
      ),
      searchForm: initGridFormData(searchForm, {
        layout: "horizontal",
        labelWidth: 80,
      }),
      visible: false,
      dataSourceParams: {status:0},
    };
  },

  methods: {
      // 操作
      async actionColumnClick(args) {
      const {
        btn: { name },
        record,
      } = args;
      switch (name) {
        // 详情
        case "detail":
        this.$refs.detaila.openModal(record);
          break;
      }
    },
    // 表格刷新
    refreshData() {
      this.$refs.logTable.getDataSource();
    },

  },

  created() {},

  mounted() {},
};
</script>
   <style lang='less' scoped>
</style>